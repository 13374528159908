

    import ArrowGame from './src/components/ArrowGame.vue';
    // import ExplosionMapper from './components/ExplosionMapper.vue';

    export default {
        name: 'App',

        components: {
            ArrowGame,
            // ExplosionMapper
        },
    };
