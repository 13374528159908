
    import { init } from '../classes/utils/CanvasSpriteSheetAnimation';
    export default {
        props: {
            data: Object,
            image: String,
        },
        data () {
            return {
                animation: null,
            };
        },
        mounted () {
            init({
                jsonData: this.data,
                element: this.$refs.canvas,
                spriteSheetName: this.image,
                loop: false,
                fps: 24,
            // onSetup: () => {
            //     setupCount++;
            //     if(setupCount >= 6){
            //         document.querySelectorAll('.awaiting-bounce').forEach(item => item.classList.add('bounce'));
            //         document.querySelectorAll('.awaiting-bounce-shadow').forEach(item => item.classList.add('bounce-shadow'));
            //         document.querySelectorAll('.awaiting-bounce-flame').forEach(item => item.classList.add('bounce-flame'));
            //     }
            // }
            });
        },
    };
