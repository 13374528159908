import jstrig from 'jstrig';
import ThrowableController from './ThrowableController';
import { radiansToDegrees } from './utils/Utilities';
export default class ThrowableControllerWithPhysics extends ThrowableController {
    constructor (data, config) {
        super(data, config);
        this.physicalThrower = null;
        this.customMesh = null;
    }
    createThrowable (customMesh) {
        super.createThrowable(customMesh);
        this.customMesh = customMesh;
        if (this.config.thrower.mass !== undefined) {
            this.physicalThrower = this.environment.createSphere({ size: this.config.thrower.size, position: this.config.thrower.position, customMesh: this.customMesh, mass: this.config.thrower.mass });
            if (this.config.thrower.start_position) {
                this.physicalThrower.body.position.x = this.startPosition.x;
                this.physicalThrower.body.position.y = this.startPosition.y;
                this.physicalThrower.body.position.z = this.startPosition.z;
            }
        }
    }
    async launchThrowable ({ x, z }) {
        const forceFactor = 15 * this.config.thrower.mass;
        const vector = {
            x: jstrig.orbit(0, z * forceFactor, radiansToDegrees(this.environment.cameraContainer.rotation.y) + (x / 2), 'cos') * -1,
            y: jstrig.orbit(0, z * forceFactor, radiansToDegrees(this.environment.cameraContainer.rotation.y) + (x / 2), 'sin') * 1,
        };


        console.log(x);
        this.environment.physics.addForce(this.physicalThrower.body, { x: vector.x, y: Math.abs(z * forceFactor), z: vector.y });

        await new Promise(resolve => setTimeout(resolve, 5000));

        // this.throwIndex++;

        this.shiftCamera(1);

        await new Promise(resolve => setTimeout(resolve, 1100));
        this.physicalThrower.mesh = null;
        this.physicalThrower = this.environment.createSphere({ size: this.config.thrower.size, position: this.config.thrower.position, customMesh: this.customMesh, mass: this.config.thrower.mass });
        this.physicalThrower.body.position.x = this.config.thrower.start_position.join ? this.config.thrower.start_position[this.throwIndex].x : this.startPosition.x;
        this.physicalThrower.body.position.y = this.config.thrower.start_position.join ? this.config.thrower.start_position[this.throwIndex].y : this.startPosition.y;
        this.physicalThrower.body.position.z = this.config.thrower.start_position.join ? this.config.thrower.start_position[this.throwIndex].z : this.startPosition.z;

    }
}
