
    import { lockup } from '../animation-data/lockup';
    import SpriteSheetAnimation from './SpriteSheetAnimation.vue';
    export default {
        components: {
            SpriteSheetAnimation,
        },
        data () {
            return {
                lockup,
                lockupImage: require('../../../img/lockup2.png'),
            };
        },
    };
