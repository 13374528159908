import jstrig from 'jstrig';
import gsap, { Sine } from 'gsap';
import { object3DSelector, basicColorMaterial, createEmptyContainer, getCanvasPosition, basicImageMaterial } from '../classes/utils/THREEHelpers';
import { degreesToRadians, radiansToDegrees, generateID } from '../classes/utils/Utilities';
import { exampleMove } from '../gameData';
import GameScene from './GameScene';

export default class ShootingGalleryScene extends GameScene {
    constructor (el, config, hasPhysics) {
        super(el, config, hasPhysics);
        this.isMouseDown = false;
        this.startingEventDetails = {};
        this.endingEventDetails = {};
        this.gameStarted = false;
        this.gameEnded = false;
        this.midThrow = false;
        this.currentMultiplier = 1;
        this.currentScore = 0;
        this.currentTime = 0;
        this.startingTime = 30;
        this.gamePaused = false;
        this.scoreIndex = -1;
        this.blockIndex = -1;


        this.bow = null;
        this.bones = [];
        this.maxTension = 50;
        this.centerMarker = null;
        this.leftMarker = null;
        this.rightMarker = null;
        this.baseCenter = { x: 0, y: .7, z: -8.5 };
        this.baseLeft = { x: 9.5, y: .7, z: -8.5 };
        this.baseRight = { x: -9.8, y: .7, z: -8.5 };
        this.center = { x: 0, y: .7, z: -8.5 };
        this.left = { x: 9.5, y: .7, z: -8.5 };
        this.right = { x: -9.8, y: .7, z: -8.5 };
        this.lString1 = null;
        this.lString2 = null;
        this.rString1 = null;
        this.rString2 = null;
        this.bowContainer = null;
        this.hasHit = false;


        this.displayClock = '';
        this.defaultDisplayClock = '0:30';
        this.timeCopy = 'Time: ';
        this.scoreCopy = 'Score: ';
        this.streakCopy = 'Streak: ';
        this.howToPlayCopy = 'How To Play';

        this.distanceBoost = 1.5;
        this.onMoved = null;
        this.trails = [];

        this.targetCenterPoint = { x: 0, y: 20, z: -43 };

        this.moveRecord = [];
        this.demoActive = false;

        this.throwID = generateID();
        this.timerRunning = false;

        this.baseTargetPositions = this.targets.map(item => ({ x: item.currentPosition.x, y: item.currentPosition.y, z: item.currentPosition.z, distance: jstrig.distance(this.targetCenterPoint, { x: item.currentPosition.x, y: item.currentPosition.y }) }));

        this.targets.forEach((item, index) => {
            // const backRow = this.baseTargetPositions[index].z < -41;
            // this.shiftDistances(this, index, backRow);
            if (index < this.targets.length - 2) {
                this.moveTarget(this, index, 'x', 1);
                this.moveTarget(this, index, 'y', 6);
                this.moveTarget(this, index, 'z', 2);
            }

        });

        this.crossbow = {
            body: null,
            arms: {
                left: null,
                right: null,
                leftTip: null,
                rightTip: null,
            },
            bones: {
                left: [],
                right: [],
            },
            bolt: null,
            boltMax: 0,
            hook: null,
            hookMax: 0,
        };

        this.bonesToMove = [];


        this.onScore = (index) => {
            this.scoreIndex = index;
        };
        this.onBlock = (index) => {
            this.scoreIndex = index;
        };
        this.onTargetRemoved = (/* h*/) => {

            // console.log(h);
            this.doTargetHitAnimation();
        };
        this.onBarrierRemoved = (/* h*/) => {

            // console.log(h);
        };

        this.onDemoComplete = null;

        this.targetFlags = this.creatTargetFlags();

        el.querySelector('canvas').addEventListener('start_game', this.startGame);
        el.querySelector('canvas').addEventListener('game_copy', e => {
            this.addCopy(e.detail);
        });
        this.checkForReady();

        this.throwableController.onThrowStarted = () => {
            this.throwableController.throwable.visible = true;
            this.crossbow.bolt.visible = false;
            this.trails.push([]);
            this.throwableController.throwable.rotation.x = degreesToRadians(60);
            this.throwID = generateID();
            const values = { v: 60, id: this.throwID };
            gsap.to(values, {
                duration: 2,
                v: -60,
                onUpdate: () => {
                    if (this.throwID === values.id) {
                        this.throwableController.throwable.rotation.x = degreesToRadians(values.v);
                    }
                },
            });

        };

        this.throwableController.onThrowCompleted = () => {
            this.throwableController.throwable.visible = false;
            this.crossbow.bolt.visible = true;
            this.trails.splice(0, 1);
        };

        let projectile2dPrevious = null;

        this.throwableController.onThrowableMoved = (t) => {
            const hitRange = 40;
            const hitBetweenPoints = false; // check half way between last point anc current point to register a hit
            const targets2d = this.targets
            .filter(item => item.active)
            .map(item => ({ position: getCanvasPosition(item.object, this.environment), hittable: item }));
            const projectile2d = getCanvasPosition(t, this.environment);
            const distances = targets2d
            .map(item => ({ distance: jstrig.distance({ x: item.position.x, y: item.position.y }, { x: projectile2d.x, y: projectile2d.y }), hittable: item.hittable }))
            .sort((a, b) => a.distance - b.distance);
            if (distances.length && distances[0].distance < hitRange) {
                distances[0].hittable.activateHit();
            }
            else if (projectile2dPrevious && hitBetweenPoints) {

                console.log('SECOND CHECK !!!!!');
                const betweenNowAndLast = { x: projectile2dPrevious.x + ((projectile2d.x - projectile2dPrevious.x) / 2), y: projectile2dPrevious.y + ((projectile2d.y - projectile2dPrevious.y) / 2) };
                const bDistances = targets2d
                .map(item => ({ distance: jstrig.distance({ x: item.position.x, y: item.position.y }, { x: betweenNowAndLast.x, y: betweenNowAndLast.y }), hittable: item.hittable }))
                .sort((a, b) => a.distance - b.distance);
                if (bDistances.length && bDistances[0].distance < hitRange) {
                    bDistances[0].hittable.activateHit();
                }
            }
            if (this.trails.length) {
                this.trails[this.trails.length - 1].push({ x: (projectile2d.x / this.environment.width) * 100, y: (projectile2d.y / this.environment.height) * 100 });
            }
            else {
                this.trails.push([]);
            }
            projectile2dPrevious = { ...projectile2d };


        };


    }
    getMousePos (e) {
        let mouseX = e.offsetX;
        let mouseY = e.offsetY;
        if (e.type == 'touchstart' || e.type == 'touchmove') {
            mouseX = e.touches[0].pageX;
            mouseY = e.touches[0].pageY;
        }
        else if (e.type == 'touchend') {
            mouseX = e.changedTouches[0].pageX;
            mouseY = e.changedTouches[0].pageY;
        }
        return { x: mouseX, y: mouseY };
    }
    onThrowStarted () {

    }
    down (e) {
        this.isMouseDown = true;
        this.startingEventDetails = this.getMousePos(e);
    }
    async launchProjectile (endEvent, startEvent) {
        this.isMouseDown = false;

        const distance = jstrig.distance(endEvent, startEvent);

        let angle = (startEvent.x - endEvent) * 5;
        if (angle > 40) {
            angle = 40;
        }
        else if (angle < -40) {
            angle = -40;
        }
        this.setTension(0);
        this.center.z = this.baseCenter.z;
        this.centerMarker.mesh.position.z = this.center.z;
        this.left.x = this.baseLeft.x;
        // this.leftMarker.mesh.position.x = this.left.x;
        this.right.x = this.baseRight.x;
        // this.rightMarker.mesh.position.x = this.right.x;
        this.left.z = this.baseLeft.z;
        // this.leftMarker.mesh.position.z = this.left.z;
        this.right.z = this.baseRight.z;
        // this.rightMarker.mesh.position.z = this.right.z;

        await this.launchThrowable({ x: radiansToDegrees(this.bowContainer.rotation.y) * -1, y: 0, z: distance * this.distanceBoost });
        this.midThrow = false;


        // console.log(this.moveRecord);
    }
    up (e) {
        if (!this.isMouseDown || !this.gameStarted || this.gameEnded || this.midThrow || this.gamePaused) {
            return;
        }
        this.midThrow = true;

        this.endingEventDetails = this.getMousePos(e);

        this.launchProjectile(this.endingEventDetails, this.startingEventDetails);

    }

    operateBow (currentEventDetails, originEvent) {
        let tension = currentEventDetails.y - originEvent.y;
        const angle = currentEventDetails.x - originEvent.x;
        if (tension < 0) {
            tension = 0;
        }

        const _t = tension <= this.maxTension ? tension : this.maxTension;
        this.setTension(_t);
        this.center.z = this.baseCenter.z + (_t * -.15);
        this.centerMarker.mesh.position.z = this.center.z;

        this.left.z = this.baseLeft.z + (_t * -.03);
        // this.leftMarker.mesh.position.z = this.left.z;
        this.left.x = this.baseLeft.x + (_t * -.044);
        // this.leftMarker.mesh.position.x = this.left.x;
        this.right.z = this.baseRight.z + (_t * -.03);
        // this.rightMarker.mesh.position.z = this.right.z;
        this.right.x = this.baseRight.x + (_t * .042);
        // this.rightMarker.mesh.position.x = this.right.x;

        // const lScale = jstrig.distance({x: this.baseCenter.x, y: this.baseCenter.z}, {x: this.baseLeft.x, y: this.baseLeft.z}) / jstrig.distance({x: this.center.x, y: this.center.z}, {x: this.left.x, y: this.left.z});


        this.bowContainer.rotation.y = degreesToRadians(angle * -.2);
        this.bowContainer.rotation.x = degreesToRadians((_t * .2) + 180);
        this.throwableController.throwable.rotation.y = degreesToRadians(angle * .2);

        if (this.onMoved) {
            this.onMoved(currentEventDetails);
        }
        this.moveRecord.push(currentEventDetails);
    }

    move (e) {
        if (!this.isMouseDown || !this.gameStarted || this.gameEnded || this.midThrow || this.gamePaused) {
            return;
        }

        // console.log('move2');
        e.preventDefault();
        const currentEventDetails = this.getMousePos(e);
        this.operateBow(currentEventDetails, this.startingEventDetails);


    }

    async loadBow () {
        const model = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/bow3.glb');
        this.bow = model;
        this.bowContainer = createEmptyContainer();
        this.throwableController.scene.add(this.bowContainer);
        this.bowContainer.add(this.bow);
        const scale = .3;
        this.bow.scale.x = scale;
        this.bow.scale.y = scale;
        this.bow.scale.z = scale;
        this.bowContainer.position.z = 0;
        this.bowContainer.position.y = 2.5;
        this.bowContainer.rotation.x = degreesToRadians(180);

        this.bow.visible = false;
        // this.bow.rotation.y = degreesToRadians(9);
        // object3DSelector(this.bow, { type: 'Mesh' })[0].rotation.y = degreesToRadians(10);


        this.bones = object3DSelector(this.bowContainer, { type: 'Bone' });


        const material = basicColorMaterial('666666');
        this.centerMarker = this.environment.createBox({ size: { x: .2, y: .1, z: .1 }, position: this.center, material });
        // this.centerMarker.mesh.z = .1;
        // this.centerMarker.mesh.x = .3;
        this.bow.add(this.centerMarker.mesh);


        // const heightL1 = jstrig.distance({x: this.center.x, y: this.center.z}, {x: this.left.x, y: this.left.z}) * .75;
        const lInnerString = this.environment.createCylinder({ size: { r: .01, y: 1 }, position: { x: 0, y: .5, z: 0 }, material });
        const lInnerContainer = createEmptyContainer();
        this.lString1 = createEmptyContainer();

        this.environment.scene.add(this.lString1);
        lInnerContainer.add(lInnerString.mesh);
        this.lString1.add(lInnerContainer);
        lInnerString.mesh.position.y = .5;


        // this.bow.add(this.lString1.mesh);

        // const heightL2 = jstrig.distance({x: this.center.x, y: this.center.z}, {x: this.left.x, y: this.left.z}) * .75;
        const rInnerString = this.environment.createCylinder({ size: { r: .01, y: 1 }, position: { x: 0, y: .5, z: 0 }, material });
        const rInnerContainer = createEmptyContainer();
        this.lString2 = createEmptyContainer();

        this.environment.scene.add(this.lString2);
        rInnerContainer.add(rInnerString.mesh);
        this.lString1.add(rInnerContainer);
        rInnerString.mesh.position.y = .5;
        lInnerContainer.rotation.x = degreesToRadians(70);
        rInnerContainer.rotation.x = degreesToRadians(-70);

        this.lString1.rotation.z = degreesToRadians(110);


        await this.loadCrossbow(this.bowContainer);

        this.crossbow.bones.left = object3DSelector(this.crossbow.arms.left, { type: 'Bone' });
        this.crossbow.bones.right = object3DSelector(this.crossbow.arms.right, { type: 'Bone' });

        const bonNames = new Set(['b2', 'b3', 'b4']);

        this.bonesToMove = [...this.crossbow.bones.left, ...this.crossbow.bones.right]
        .filter(item => bonNames.has(item.name))
        .map(item => ({ bone: item, angle: item.rotation.z.toString() }));

        this.crossbow.hook.add(this.lString1);
        this.crossbow.hook.add(this.lString2);

        this.setTension(0);


    }

    async loadCrossbow (parent) {
        const bodyModel = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_body_2.glb');
        this.crossbow.body = bodyModel;
        const bodyBolt = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_bolt.glb');
        this.crossbow.bolt = bodyBolt;
        this.crossbow.boltMax = this.crossbow.bolt.position.x;

        const throwBolt = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_bolt.glb');
        this.throwableController.throwable.add(throwBolt);

        throwBolt.position.x = 0;
        throwBolt.position.y = 0;
        throwBolt.position.z = -5;

        throwBolt.scale.x = 10;
        throwBolt.scale.y = 10;
        throwBolt.scale.z = 10;

        throwBolt.rotation.y = degreesToRadians(90);
        this.throwableController.throwable.visible = false;
        this.throwableController.throwable.rotation.x = degreesToRadians(45);


        const leftModel = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_arm_2.glb');
        this.crossbow.arms.left = leftModel;
        const rightModel = await this.environment.loadModel('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_arm_2.glb');
        this.crossbow.arms.right = rightModel;
        const crossbowContainer = createEmptyContainer();
        this.throwableController.scene.add(crossbowContainer);
        crossbowContainer.add(this.crossbow.body);
        crossbowContainer.add(this.crossbow.bolt);
        crossbowContainer.add(this.crossbow.arms.left);
        crossbowContainer.add(this.crossbow.arms.right);
        this.crossbow.arms.right.scale.z = this.crossbow.arms.right.scale.z * -1;

        crossbowContainer.position.y = 0;

        crossbowContainer.rotation.y = degreesToRadians(90);
        const crossbowScale = 4;
        crossbowContainer.scale.x = crossbowScale;
        crossbowContainer.scale.y = crossbowScale * -1;
        crossbowContainer.scale.z = crossbowScale;

        parent.add(crossbowContainer);

        this.crossbow.hook = object3DSelector(bodyModel, { type: 'Mesh', name: '18_low' })[0];
        this.crossbow.hookMax = this.crossbow.hook.position.x;

        this.crossbow.arms.leftTip = object3DSelector(this.crossbow.arms.left, { type: 'SkinnedMesh', name: '44_low' })[0];
        this.crossbow.arms.rightTip = object3DSelector(this.crossbow.arms.right, { type: 'SkinnedMesh', name: '44_low' })[0];

        this.textureCrossbow();


    }

    textureCrossbow () {
        const orangeMaterial = basicColorMaterial('ef8967');
        const greyMaterial = basicColorMaterial('ffffff');
        const silverMaterial = basicColorMaterial('999999');
        const woodMaterial = basicImageMaterial('https://eprize-content.s3.amazonaws.com/three_js_demo/assets/wood.jpg');
        object3DSelector(this.crossbow.arms.left, { type: 'SkinnedMesh' }).forEach(item => item.material = woodMaterial);
        object3DSelector(this.crossbow.arms.right, { type: 'SkinnedMesh' }).forEach(item => item.material = woodMaterial);
        object3DSelector(this.crossbow.body, { type: 'Mesh' }).forEach(item => item.material = orangeMaterial);
        object3DSelector(this.crossbow.bolt, { type: 'Mesh' }).forEach(item => item.material = greyMaterial);
        object3DSelector(this.throwableController.throwable, { type: 'Mesh' }).forEach(item => item.material = greyMaterial);
        this.crossbow.hook.material = silverMaterial;
    }

    setTension (t) {
        this.throwableController.throwable.scale.z = -.39;
        this.throwableController.throwable.scale.y = .39;
        this.throwableController.throwable.scale.x = .39;
        this.bow.position.z = -2 + (t * .04);

        const crossBowTension = this.maxTension - t;
        this.bonesToMove.forEach(item => {
            item.bone.rotation.z = Number(item.angle) + degreesToRadians(crossBowTension * .25);
        });
        this.crossbow.hook.position.x = this.crossbow.hookMax - (crossBowTension * .007);
        this.crossbow.bolt.position.x = this.crossbow.boltMax - (crossBowTension * .007);

        this.lString1.scale.z = .6 + (crossBowTension * .003);

        // this.lString1.scale.x = .8 + (crossBowTension * .005);

        this.lString1.scale.y = .6 + (.7 - (crossBowTension * .014));

        this.lString1.rotation.z = degreesToRadians(100 + (crossBowTension * .5));

        // this.lString1.children[0].rotation.x = degreesToRadians(crossBowTension * .25);
    }

    startGame () {
        this.gameStarted = true;
        this.gameEnded = false;

        if (this.gamePaused) {
            this.gamePaused = false;
            this.throwableController.unPause();
            return;
        }

        this.currentTime = this.startingTime;
        this.currentScore = 0;
        this.hitStreak = 1;
        this.displayClock = this.defaultDisplayClock;

    }
    startTimer () {
        this.timerRunning = true;
        const gameTimeInterval = setInterval(() =>{
            if (!this.gamePaused) {
                this.currentTime -= 1;
                if (this.currentTime === 10) {
                    this.moveBonusTargets(this, this.targets.length - 2);
                }
                if (this.currentTime === 20) {
                    this.moveBonusTargets(this, this.targets.length - 1);
                }
            }

            if (this.currentTime < 0) {
                this.currentTime = 0;
            }
            this.displayClock = (String(this.currentTime).length < 2) ? `0:0${this.currentTime}` : `0:${this.currentTime}`;
            if (this.currentTime == 0 && !this.midThrow) {
                clearInterval(gameTimeInterval);
                this.timerRunning = false;
                this.gameEnded = true;
                this.isMouseDown = false;
                const gameOver = new CustomEvent('game_over', { detail: this.currentScore });
                document.body.dispatchEvent(gameOver);
            }
        }, 1000);

        this.targets.forEach(item => { item.active = true; });
    }
    openHowToPlay () {
        this.gamePaused = true;
        this.throwableController.pause();
        const howToPlay = new CustomEvent('how_to_play');
        document.body.dispatchEvent(howToPlay);
    }
    checkForReady () {

        if (this.throwableController && this.throwableController.scene && this.throwableController.throwable) {
            this.onReady();
        }
        else {
            setTimeout(() => { this.checkForReady(); }, 10);
        }

    }
    getBoneByName (name) {
        return this.bones.find(item => item.name === name);
    }

    async doTargetHitAnimation () {
        this.hasHit = true;
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.hasHit = false;
    }
    onReady () {
        this.loadBow();
    }

    creatTargetFlags () {
        return this.targets.map(item => {
            const center = this.environment.createSphere({ size: { r: 1 }, position: { x: 0, y: 0, z: 0 }, material: basicColorMaterial('cccc00') });
            item.object.add(center.mesh);
            center.mesh.visible = false;
            const satellite = this.environment.createSphere({ size: { r: 1 }, position: { x: 1, y: 0, z: 0 }, material: basicColorMaterial('00cccc') });
            item.object.add(satellite.mesh);
            satellite.mesh.position.x = 1;
            satellite.mesh.visible = false;

            return { center, satellite };
        });
    }

    getTranslatedTargets () {
        return this.targetFlags.map(item => {
            const centerPos = getCanvasPosition(item.center.mesh, this.environment);
            const satellitePos = getCanvasPosition(item.satellite.mesh, this.environment);
            const scale = (satellitePos.x - centerPos.x) * .02;
            return { ...centerPos, scale };
        });
    }

    moveTarget (scope, index, axis, range = 5) {
        const targetValues = { v: scope.targets[index].currentPosition[axis] };

        gsap.to(targetValues, {
            duration: (Math.random()) + 1.5,
            v: this.baseTargetPositions[index][axis] + ((Math.random() * range) - (range / 2)),
            onComplete: () => {
                scope.moveTarget(scope, index, axis);
            },
            onUpdate: () => {
                scope.targets[index].currentPosition[axis] = targetValues.v;
            },
            ease: Sine.easeInOut,
        });
    }

    moveBonusTargets (scope, index) {
        const targetValues = { x: scope.targets[index].currentPosition.x, y: scope.targets[index].currentPosition.y, z: scope.targets[index].currentPosition.z };

        gsap.to(targetValues, {
            duration: 2.5,
            y: 12,
            onUpdate: () => {
                scope.targets[index].currentPosition.y = targetValues.y;
            },
            ease: Sine.easeInOut,
        });
        gsap.to(targetValues, {
            delay: 2.5,
            duration: 2.5,
            y: 50,
            onUpdate: () => {
                scope.targets[index].currentPosition.y = targetValues.y;
            },
            ease: Sine.easeInOut,
        });
        [...new Array(8).keys()].forEach((item, _index) => {
            gsap.to(targetValues, {
                delay: _index * .5,
                duration: .5,
                x: _index % 2 === 0 ? 16 : -16,
                onUpdate: () => {
                    scope.targets[index].currentPosition.x = targetValues.x;
                },
                ease: Sine.easeInOut,
            });
        });
    }

    shiftDistances (scope, index, backRow) {
        const targetValues = { x: scope.targets[index].currentPosition.x, y: scope.targets[index].currentPosition.y };
        const angle = jstrig.angle(this.targetCenterPoint, { x: this.baseTargetPositions[index].x, y: this.baseTargetPositions[index].y });
        const distance = jstrig.distance(targetValues, { x: this.baseTargetPositions[index].x, y: this.baseTargetPositions[index].y });
        let newDist = distance < 1 ? 3 : -3;
        if (backRow) {
            newDist = distance > -1 ? -3 : 3;
        }
        const newValues = { x: jstrig.orbit(this.baseTargetPositions[index].x, distance + newDist, angle, 'cos'), y: jstrig.orbit(this.baseTargetPositions[index].y, distance + newDist, angle, 'sin') };
        gsap.to(targetValues, {
            duration: 3,
            ...newValues,
            onComplete: () => {
                scope.shiftDistances(scope, index, backRow);
            },
            onUpdate: () => {
                scope.targets[index].currentPosition.x = targetValues.x;
                scope.targets[index].currentPosition.y = targetValues.y;
            },
            ease: Sine.easeInOut,
        });
    }

    async doExampleMove () {
        const baseHeight = 676;
        const adjustedExampleMove = exampleMove.map(item => ({ x: item.x, y: item.y + ((window.innerHeight - baseHeight) / 2) }));
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.demoActive = true;
        let moveIndex = 0;
        await new Promise(resolve => setTimeout(resolve, 100));
        while (moveIndex < adjustedExampleMove.length) {

            // console.log(adjustedExampleMove[moveIndex]);
            this.operateBow(adjustedExampleMove[moveIndex], adjustedExampleMove[0]);
            moveIndex++;
            await new Promise(resolve => setTimeout(resolve, 10));
        }
        this.demoActive = false;
        this.launchProjectile(exampleMove[adjustedExampleMove.length - 1], adjustedExampleMove[0]);
        if (this.onDemoComplete) {
            this.onDemoComplete();
        }
    }

}
