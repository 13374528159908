

    // import Vue2Mp3Player from 'vue2-mp3-player';
    import MusicIcon from './images/MusicIcon.vue';
    import MuteIcon from './images/MuteIcon.vue';


    export default {
        components: {
            // Vue2Mp3Player,
            MusicIcon,
            MuteIcon,
        },
        props: {
            clip: String,
        },
        data () {
            return {
                isMuted: true,
            };
        },
        methods: {
            playAudo () {
                if (this.$refs && this.$refs.audo) {
                    this.isMuted = !this.isMuted;
                    if (!this.isMuted) {
                        this.$refs.audo.setAttribute('loop', 'loop');
                        this.$refs.audo.play();
                    }
                    else {
                        this.$refs.audo.pause();
                    }

                }

            },
        },
    };
