
    import ShootingGalleryScene from '../classes/ShootingGalleryScene';
    import { explosion, explosionPIXI } from '../animation-data/explosions';
    import gameData from '../gameData';
    import GameHeader from './GameHeader.vue';
    import GameTarget from './GameTarget.vue';
    import ModalOverlay from './ModalOverlay.vue';
    import BowUI from './BowUI.vue';
    import SpriteSheetAnimation from './SpriteSheetAnimation.vue';
    import Lockup from './LockUp.vue';
    import SocialShare from './SocialShare.vue';
    import AudioPlayer from './AudioPlayer.vue';
    import PointerIcon from './images/PointerIcon.vue';
    import ContentPreloader from './ContentPreloader.vue';
    import LanscapeBlocker from './LanscapeBlocker.vue';
    // import { preload } from '../classes/utils/Utilities';
    const basePosition = { x: 50, y: 60 };
    export default {
        components: {
            SpriteSheetAnimation,
            GameTarget,
            GameHeader,
            BowUI,
            ModalOverlay,
            SocialShare,
            AudioPlayer,
            PointerIcon,
            Lockup,
            ContentPreloader,
            LanscapeBlocker,
        },
        data () {
            return {
                game: null,
                explosion,
                explosionPIXI,
                targetStatuses: [],
                targetIDs: [
                    'immersion',
                    'immersion',
                    'revive',
                    'revive',
                    'downtime',
                    'downtime',
                    'party',
                    'party',
                    'hype',
                    'hype',
                    'win',
                    'win',
                ],
                scoreMatrix: {
                    win: 100,
                    party: 50,
                    hype: 50,
                    downtime: 50,
                    immersion: 50,
                    revive: 50,
                },
                score: 0,
                peiceCount: [],
                uiPosition: { ...basePosition },
                baseY: basePosition.y,
                plays: 0,
                tMinus: 0,
                onLanding: true,
                loading: true,
                researchURL: 'https://info.dentsu.com/forthegame_dentsuglobal_gemquest',
            };
        },
        computed: {
            targetStats () {
                return this.game && this.game.targets ?
                    this.game.getTranslatedTargets() :
                    [];
            },
            zOrder () {
                return this.targetStats
                    .map((item, index) => ({ scale: item.scale, index }))
                    .sort((a, b) => a.scale - b.scale);
            },
            time () {
                return this.game ? this.game.currentTime : 0;
            },
            trails () {
                return this.game && this.game.trails && this.game.trails.length ?
                    this.game.trails[this.game.trails.length - 1] :
                    [];
            },
            canShoot () {
                return (
                    this.game &&
                    this.game.throwableController.canThrow &&
                    this.game.throwableController.canThrow
                );
            },
            demoActive () {
                return this.game.demoActive;
            },
            ieVersion () {
                const ua = window.navigator.userAgent;
                const msie = ua.indexOf('MSIE ');

                if (msie > 0) {
                    // If Internet Explorer, return version number
                    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
                }
                return -1;
            },
        },

        methods: {
            async startApp () {
                const config = gameData;
                await new Promise((resolve) => setTimeout(resolve, 1000));
                this.game = new ShootingGalleryScene(
                    this.$refs.gameContainer,
                    config,
                    false,
                );
                this.targetStatuses = this.game.targets.map(() => false);
                this.game.onScore = (index) => {
                    this.targetStatuses[index] = true;
                    this.score += this.scoreMatrix[this.targetIDs[index]];
                    this.peiceCount.push(this.targetIDs[index]);
                };

                this.game.onMoved = (data) => {
                    if (this.$refs.ui) {
                        const bounds = this.$refs.ui.$el.getBoundingClientRect();
                        this.uiPosition.y = (data.y / bounds.height) * 100;
                        this.uiPosition.x = (data.x / bounds.width) * 100;
                    }
                };
                this.game.onDemoComplete = () => {
                    this.uiPosition = { ...basePosition };
                    this.countDown(true);
                };
            },
            uiDownHandler (e) {
                if (!this.demoActive) {
                    this.game.down(e);
                }
            },
            mouseDownHandler (e) {
                this.game.down(e);
            },
            mouseUpHandler (e) {
                this.game.up(e);
                if (!this.demoActive) {
                    this.game.up(e);
                    this.uiPosition = { ...basePosition };
                }
            },
            onMouseMove (e) {
                if (!this.demoActive) {
                    e.preventDefault();
                    this.game.move(e);
                }
            },
            startGame () {
                this.score = 0;
                this.game.startGame();
                if (this.plays === 0) {
                    this.game.doExampleMove();
                }
                else {
                    this.countDown();
                }
            },
            async countDown (shooting) {
                this.plays++;
                if (shooting) await new Promise((resolve) => setTimeout(resolve, 2000));
                this.targetStatuses = this.game.targets.map(() => false);
                this.score = 0;
                this.tMinus = 3;
                await new Promise((resolve) => setTimeout(resolve, 1000));
                this.tMinus = 2;
                await new Promise((resolve) => setTimeout(resolve, 1000));
                this.tMinus = 1;
                await new Promise((resolve) => setTimeout(resolve, 1000));
                this.tMinus = 0;
                this.game.startTimer();
                this.game.targets.forEach((item) => {
                    item.active = true;
                });
            },
            loadingComplete () {
                this.loading = false;
                this.startApp();
            },
        },
    };
