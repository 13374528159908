import * as THREE from 'three';
import CustomMeshController from '../../classes/engine/controllers/CustomMeshController';
import { basicImageMaterial } from './THREEHelpers';
import { degreesToRadians, ShapeTypes } from './Utilities';
function createMesh (environment, source, handler) {
    if (source.model) {
        const meshController = new CustomMeshController({ environment }, source.model);
        meshController.onLoaded = (m) => {
            const customMesh = environment.selector(m, { type: 'Group' })[0];
            if (source.rotation) {
                customMesh.rotation.x = degreesToRadians(source.rotation.x);
                customMesh.rotation.y = degreesToRadians(source.rotation.y);
                customMesh.rotation.z = degreesToRadians(source.rotation.z);
            }
            if (handler) {
                handler(customMesh);
            }
        };
    }
    else {
        const { size } = source;
        const position = { x: 0, y: 0, z: 0 };
        let material = null;
        let obj = {};
        if (source.texture) {
            const tex = source.texture;
            material = basicImageMaterial(tex);
        }
        else if (source.material) {
            material = new THREE.MeshLambertMaterial({ color: Number(`0x${source.material.color}`) });
        }
        switch (source.type) {
            case ShapeTypes.SPHERE:{
                obj = environment.createSphere({ size, position, material });
                break;
            }
            case ShapeTypes.BOX:{
                obj = environment.createBox({ size, position, material });
                break;
            }
            case ShapeTypes.PLANE:{
                obj = environment.createPlane({ size, position, material });
                break;
            }
            case ShapeTypes.CYLINDER:{
                obj = environment.createCylinder({ size, position, material });
                break;
            }
        }
        if (source.rotation) {
            obj.mesh.rotation.x = degreesToRadians(source.rotation.x);
            obj.mesh.rotation.y = degreesToRadians(source.rotation.y);
            obj.mesh.rotation.z = degreesToRadians(source.rotation.z);
        }
        if (handler) {
            handler(obj.mesh);
        }
    }
}
export { createMesh };
