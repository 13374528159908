
    import jstrig from 'jstrig';
    export default {
        props: {
            position: Object,
            base: Number,
        },
        data () {
            return {
                linePoints: [
                    { x: 0, y: this.base },
                    { x: this.position.x, y: this.position.y },
                    { x: 100, y: this.base },
                ],
                radius: 6,
            };
        },
        computed: {
            leftInnerEnd () {
                const angle = jstrig.angle(this.position, this.linePoints[0]);
                return {
                    x: jstrig.orbit(this.position.x, this.radius * 1.5, angle, 'cos'),
                    y: jstrig.orbit(this.position.y, this.radius * 1.5, angle, 'sin'),
                };
            },
            rightInnerEnd () {
                const angle = jstrig.angle(this.position, this.linePoints[2]);
                return {
                    x: jstrig.orbit(this.position.x, this.radius * 1.5, angle, 'cos'),
                    y: jstrig.orbit(this.position.y, this.radius * 1.5, angle, 'sin'),
                };
            },
        // rightInnerEnd(){

        // }
        },
        methods: {
            mouseDownHandler (e) {
                this.$emit('down', e);
            },
        },
    };
