import * as THREE from 'three';
import gsap, { Linear } from 'gsap';
import LocatableController from './engine/controllers/LocatableController';
import { createMesh } from './utils/GameUtils';
export default class HittableController extends LocatableController {
    constructor (data, targetData, proximity) {
        super(data, targetData.position.join ? targetData.position[targetData.startIndex] : targetData.position);
        this.active = true;
        this.size = targetData.size;
        this.throwableController = null;
        this.hitDistance = proximity;
        this.mesh = null;
        this.object = null;
        this.maxHits = targetData.hits ? targetData.hits : 0;
        this.hitCount = 0;
        this.active = true;

        this.positionList = targetData.position.join ? targetData.position : [];

        this.serializedPositionList = JSON.stringify(this.positionList);

        this.onHit = null;
        this.onRemoved = null;

        if (targetData.object) {

            this.mesh = createMesh(this.environment, targetData.object, (mesh) => this.createObject(mesh));

        }

        this.cyclePositions(targetData.startIndex);


    }

    cyclePositions (index = 0) {

        if (this.positionList.length) {
            const posList = JSON.parse(this.serializedPositionList);
            const positionData = { x: this.currentPosition.x, y: this.currentPosition.y, z: this.currentPosition.z };
            gsap.to(positionData, {
                duration: posList[index].duration !== undefined ? posList[index].duration : 1,
                x: posList[index].x,
                y: posList[index].y,
                z: posList[index].z,
                onComplete: () => {
                    index++;
                    if (index >= posList.length) {
                        index = 0;
                    }
                    this.cyclePositions(index);
                },
                onUpdate: () => {
                    this.currentPosition.x = positionData.x;
                    this.currentPosition.y = positionData.y;
                    this.currentPosition.z = positionData.z;
                },
                ease: Linear.easeNone,
            });
        }
    }

    createObject (mesh) {
        this.object = mesh;

        // console.log(this.object);
        this.environment.scene.add(mesh);
    }

    update () {
        if (this.object) {
            this.object.position.x = this.currentPosition.x;
            this.object.position.y = this.currentPosition.y;
            this.object.position.z = this.currentPosition.z;
        }
        if (this.active && this.throwableController && this.throwableController.throwable) {
            const thrower = this.throwableController.throwable;
            let hit = false;
            if (this.size) {
                const insideX = this.withinDimension(thrower.position.x, 'x');
                const insideY = this.withinDimension(thrower.position.y, 'y');
                const insideZ = this.withinDimension(thrower.position.z, 'z');
                hit = insideX && insideY && insideZ;
            }
            else {
                hit = new THREE.Vector3(this.currentPosition.x, this.currentPosition.y, this.currentPosition.z).distanceTo(new THREE.Vector3(thrower.position.x, thrower.position.y, thrower.position.z)) <= this.hitDistance;
            }
            if (hit && this.active) {
                this.activateHit();
                // this.hitCount++;
                // if(this.maxHits && this.maxHits <= this.hitCount){
                //     this.active = false;
                //     this.remove();
                // }
                // // eslint-disable-next-line no-console
                // console.log('hit');
                // if(this.onHit){
                //     this.onHit();
                // }
            }
        }
    }
    activateHit () {
        this.hitCount++;
        if (this.maxHits && this.maxHits <= this.hitCount) {
            this.active = false;
            this.remove();
        }

        // console.log('hit');
        if (this.onHit) {
            this.onHit();
        }
    }
    withinDimension (value, d) {
        return value > this.currentPosition[d] - (this.size[d] / 2) && value < this.currentPosition[d] + (this.size[d] / 2);
    }
    remove () {

        // console.log('removed');
        if (this.object) {
            this.object.visible = false;
        }
        if (this.onRemoved) {
            this.onRemoved(this);
        }
        // this.mesh.hi
    }
}
