function CanvasSpriteSheetAnimation (customConfigs) {
    const configs = {
        imagePath: '',
        loop: true,
        step: 0,
        fps: 30,
        scale: 1,
        onSetup: null,
        onSetupArgs: [],
        onComplete: null,
        onCompleteArgs: [],
        onLoop: null,
        onLoopArgs: [],
        onFrame: null,
        onFrameArgs: [],
        coords: null, // allows for same sprite animation to appear multiple times on same canvas [{x: number, y: number, step: number}...]
    };

    this.gotoStep = function (step) {
        configs.step = step;
    };

    this.getConfig = function (name) {
        return name ? configs[name] : configs;
    };

    this.setupCanvasSpriteSheet = ({ width, height }) => {
        configs.canvasElement = configs.element;
        configs.context = configs.canvasElement.getContext('2d');

        configs.canvasElement.setAttribute('width', width ? width : configs.canvasElement.getBoundingClientRect().width * configs.scale);
        configs.canvasElement.setAttribute('height', height ? height : configs.canvasElement.getBoundingClientRect().height * configs.scale);

        configs.image = new Image();
        configs.image.src = configs.imageName;
        configs.image.onload = () => {
            if (configs.onSetup) {
                configs.onSetup(this, ...configs.onSetupArgs);
            }
            redraw();
        };
    };

    const redraw = () => {
        if (configs.onFrame) {
            configs.onFrame(this, ...configs.onFrameArgs);
        }
        const numOfFrames = configs.frames.length;
        if (document.body.contains(configs.canvasElement)) {
            clearCanvas();
            renderCroppedImage();
            setTimeout(() => {
                configs.step++;
                if (configs.loop && configs.step >= numOfFrames) {
                    if (configs.onLoop) {
                        configs.onLoop(this, ...configs.onLoopArgs);
                    }
                    configs.step = 0;
                }
                else if (!configs.loop && configs.step >= numOfFrames && configs.onComplete) {
                    configs.onComplete(this, ...configs.onCompleteArgs);
                }
                if (configs.step < numOfFrames) {
                    redraw();
                }
            }, 1000 / configs.fps);
        }
    };

    function clearCanvas () {
        configs.context.clearRect(0, 0, configs.canvasElement.width, configs.canvasElement.height);
        applyScaling();
    }

    function renderCroppedImage () {
        if (configs.coords) {
            configs.coords.forEach(item => {
                const currentStep = (configs.step + (item.step ? item.step : 0)) % configs.frames.length;
                const { x, y, w, h } = configs.frames[currentStep].frame;
                const sourceSize = configs.frames[currentStep].spriteSourceSize;
                configs.context.drawImage(configs.image, x | 0, y | 0, w, h, (sourceSize.x | 0) + item.x, (sourceSize.y | 0) + item.y, w, h);
            });
        }
        else {
            const { x, y, w, h } = configs.frames[configs.step].frame;
            const sourceSize = configs.frames[configs.step].spriteSourceSize;
            configs.context.drawImage(configs.image, x | 0, y | 0, w, h, sourceSize.x | 0, sourceSize.y | 0, w, h);
        }

    }

    function applyScaling () {
        configs.canvasElement.style.transform = 'scale(1, 1)';

        const cw = configs.canvasElement.getBoundingClientRect().width;
        const ch = configs.canvasElement.getBoundingClientRect().height;
        const ww = configs.element.getBoundingClientRect().width;
        const wh = configs.element.getBoundingClientRect().height;

        const scaleAmtX = ww / cw;
        const scaleAmtY = wh / ch;

        configs.canvasElement.style.transform = 'scale(' + scaleAmtX + ', ' + scaleAmtY + ')';
    }

    (function () {
        Object.keys(customConfigs).forEach(key => {
            configs[key] = customConfigs[key];
        });
    }());
}

async function init (settings) {
    const data = settings.jsonData;
    const canvasWrapperElements = [settings.element];
    return canvasWrapperElements.map((element, index) => {
        const { fps } = settings;
        const frames = data.frames.join ? data.frames : data.animations[Object.keys(data.animations)[0]].map(item => data.frames[item]);

        const animation = new CanvasSpriteSheetAnimation({
            imageName: settings.spriteSheetName,
            frames,
            element,
            step: index * fps,
            ...settings,
        });
        const { sourceSize } = data.frames[Object.keys(data.frames)[0]];
        return animation.setupCanvasSpriteSheet({ width: sourceSize.w, height: sourceSize.h });
    });
}

export { init };

// (function() {
//     let setupCount = 0;

// })();


// (function() {
//     init({
//         jsonFileName: 'flames',
//         elementSelector: '.game-flame',
//         spriteSheetName: 'flames.png',
//         fps: 24,
//     });
// })();

// (function() {
//     const candlesContainer = document.querySelector('.game-setup-candles-container');
//     const canvas = document.querySelector('.game-setup-candles-container > canvas');
//     const gameContainer = document.querySelector('.game-interaction');
//     init({
//         jsonFileName: 'candles',
//         elementSelector: '.game-setup-candles-container',
//         spriteSheetName: 'candles.png',
//         fps: 24,
//         onSetup: () => {
//             candlesContainer.classList.remove('loading-animation');
//             candlesContainer.classList.add('open-tear');
//         },
//         onFrame: (animation) => {
//             if(animation.getConfig('step') > 65 && ![...candlesContainer.classList].includes('close-tear')){
//                 candlesContainer.classList.remove('open-tear');
//                 candlesContainer.classList.add('close-tear');
//             }
//         },
//         onComplete: () => {
//             canvas.classList.add('complete');
//             canvas.style.transform = 'unset';
//             candlesContainer.classList.add('ready-to-play');
//             gameContainer.classList.add('ready-to-play');
//         },
//         loop: false
//     });
// })();
