
    import { explosion } from '../animation-data/explosions';
    import SpriteSheetAnimation from './SpriteSheetAnimation.vue';
    export default {
        components: {
            SpriteSheetAnimation,
        },
        props: {
            target: Object,
            index: Number,
            status: Boolean,
            targetId: String,
            z: Number,
            points: Number,
        },
        data () {
            return {
                explosion,
                explosionImage: require('../../../img/explosion4.png'),
            };
        },
    };
