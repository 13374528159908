
    const imagesToLoad = [
        require('../../../img/lockup2.png'),
        require('../../../img/explosion4.png'),
        require('../../../img/win.png'),
        require('../../../img/hype.png'),
        require('../../../img/immersion.png'),
        require('../../../img/party.png'),
        require('../../../img/revive.png'),
        require('../../../img/downtime.png'),
    ];
    const modelsToLoad = [
        'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/bow3.glb',
        'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_body_2.glb',
        'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_bolt.glb',
        'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/crossbow_arm_2.glb',

    ];
    export default {
        data () {
            return {
                images: imagesToLoad,
                statuses: imagesToLoad.map(() => false),
                modelsLoaded: 0,
            };
        },
        mounted () {

            modelsToLoad.forEach(item => {
                fetch(item)
                    .then(() => this.modelLoaded())
                    .catch(e => { console.error(e); });
            });
        },
        methods: {
            loaded (index) {
                this.statuses[index] = true;

                this.checkLoadStatus();
            // if(this.statuses.filter(item => item === true).length === this.statuses.length){
            //     this.$emit('completed')
            // }
            },
            modelLoaded () {
                this.modelsLoaded++;
                this.checkLoadStatus();
            },
            checkLoadStatus () {

                const laodedImages = this.statuses.filter(item => item === true).length;
                const loadedModels = this.modelsLoaded;

                // console.log('loaded images', laodedImages);

                // console.log('loaded models', loadedModels);
                if (laodedImages === this.statuses.length && loadedModels >= modelsToLoad.length) {
                    this.$emit('completed');
                }
            },
        },
    };
