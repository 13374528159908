import * as THREE from 'three';
import BaseScene from './engine/BaseScene';
import ThrowableController from './ThrowableController';
import ThrowableControllerWithPhysics from './ThrowableControllerWithPhysics';
import CustomMeshController from './engine/controllers/CustomMeshController';
import LightController, { LightTypes } from './engine/controllers/LightController';
import { ShapeTypes, degreesToRadians } from './utils/Utilities';
import { basicImageMaterial } from './utils/THREEHelpers';
import HittableController from './HittableController';
export default class GameScene extends BaseScene {
    constructor (el, config, hasPhysics) {
        super();
        this.targets = null;
        this.onScore = null;
        this.onBlock = null;
        this.onTargetRemoved = null;
        this.onBarrierRemoved = null;
        this.config = config;
        this.setup(el);
        this.throwableController = this.createThrowableController(hasPhysics);
        const initialPosition = this.config.camera_position.join ? this.config.camera_position[0] : this.config.camera_position;
        this.environment.cameraContainer.position.x = initialPosition.x;
        this.environment.cameraContainer.position.y = initialPosition.y;
        this.environment.cameraContainer.position.z = initialPosition.z;
        if (this.config.camera_rotation) {
            const initialRotation = this.config.camera_rotation.join ? this.config.camera_rotation[0] : this.config.camera_rotation;
            this.environment.cameraContainer.rotation.x = degreesToRadians(initialRotation.x);
            this.environment.cameraContainer.rotation.y = degreesToRadians(initialRotation.y);
            this.environment.cameraContainer.rotation.z = degreesToRadians(initialRotation.z);
        }


        this.renderLights();

        this.renderObjects();

        this.targets = this.setupHitters(this.throwableController, 'targets');
        this.barriers = this.setupHitters(this.throwableController, 'barriers');
    }

    createThrowableController (hasPhysics) {
        return hasPhysics ? new ThrowableControllerWithPhysics({ environment: this.environment }, this.config) : new ThrowableController({ environment: this.environment }, this.config);
    }

    renderLights () {
        const light = new LightController({ environment: this.environment });
        this.config.lights.map(item => light.addLight({ type: LightTypes.DIRECTIONAL, color: Number(`0x${item.color}`), intensity: item.intensity, target: item.target }));
    }

    renderObjects () {
        this.config.objects.forEach(item => {
            let obj = null;
            let mass = null;
            if (item.mass !== null) {
                mass = item.mass;
            }
            const sizeAndPosition = { size: item.size, position: item.position };
            let material = null;
            if (item.texture) {
                const tex = item.texture;
                material = basicImageMaterial(tex);
            }
            else if (item.material) {
                material = new THREE.MeshLambertMaterial({ color: Number(`0x${item.material.color}`) });
            }

            switch (item.type) {
                case ShapeTypes.PLANE:{
                    obj = this.environment.createPlane({ ...sizeAndPosition, material, mass });
                    break;
                }
                case ShapeTypes.BOX:{
                    obj = this.environment.createBox({ ...sizeAndPosition, material, mass });
                    break;
                }
                case ShapeTypes.SPHERE:{
                    obj = this.environment.createSphere({ ...sizeAndPosition, material, mass });
                    break;
                }
                case ShapeTypes.CYLINDER:{
                    obj =  this.environment.createCylinder({ ...sizeAndPosition, material });
                    break;
                }
                default:{
                    const modelController = new CustomMeshController({ environment: this.environment }, item.model);
                    modelController.onLoaded = (m) => {
                        const customMesh = this.environment.selector(m, { type: 'Group' })[0];
                        customMesh.position.x = item.position.x;
                        customMesh.position.y = item.position.y;
                        customMesh.position.z = item.position.z;

                        customMesh.scale.x = item.scale.x;
                        customMesh.scale.y = item.scale.y;
                        customMesh.scale.z = item.scale.z;

                        customMesh.rotation.x = degreesToRadians(item.rotation.x);
                        customMesh.rotation.y = degreesToRadians(item.rotation.y);
                        customMesh.rotation.z = degreesToRadians(item.rotation.z);
                    };
                    break;
                }

            }
            if (obj) {
                if (item.visible !== undefined) {
                    obj.mesh.visible = item.visible;
                }
                obj.mesh.rotation.x = degreesToRadians(item.rotation.x);
                obj.mesh.rotation.y = degreesToRadians(item.rotation.y);
                obj.mesh.rotation.z = degreesToRadians(item.rotation.z);
            }

        });
    }

    async launchThrowable ({ x, y, z }) {
        await this.throwableController.launchThrowable({ x, y, z: z * this.config.thrower.force_multiplier });

    }

    getThrowableController () {
        return this.throwableController;
    }

    getWidth () {
        return this.config.width;
    }
    getHeight () {
        return this.config.height;
    }

    setupHitters (throwableController, property) {
        const canScore = property === 'targets';
        const canBlock = property === 'barriers';
        const hitters = this.config[property] ? this.config[property].map((item, index) => {
            const proximity = this.config.score_proximity;
            const hitter = new HittableController({ environment: this.environment }, item, proximity);
            hitter.throwableController = throwableController;
            hitter.onHit = () => {
                if (this.onScore && canScore && this.throwableController.canScore) {
                    this.throwableController.canScore = false;
                    this.onScore(index);
                }
                if (this.onBlock && canBlock) {
                    this.onBlock(index);
                }
                if (!this.config.suspendReset) {
                    throwableController.currentTweenKey = '';
                    throwableController.reset();
                    throwableController.canThrow = true;
                }

            };
            hitter.onRemoved = (h) => {
                if (canScore) {
                    this.onTargetRemoved(h);
                }
                if (canBlock) {
                    this.onBarrierRemoved(h);
                }

            };
            return hitter;
        }) : [];
        return hitters;
    }
}
