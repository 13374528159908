const explosionJsonPIXI = `
    {"frames": {

    "fx_hype_00001.png":
    {
        "frame": {"x":366,"y":154,"w":134,"h":129},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":30,"y":32,"w":134,"h":129},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00002.png":
    {
        "frame": {"x":1,"y":1,"w":182,"h":183},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":5,"y":7,"w":182,"h":183},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00003.png":
    {
        "frame": {"x":186,"y":175,"w":176,"h":177},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":8,"y":8,"w":176,"h":177},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00004.png":
    {
        "frame": {"x":186,"y":1,"w":172,"h":178},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":10,"y":6,"w":172,"h":178},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00005.png":
    {
        "frame": {"x":1,"y":185,"w":157,"h":172},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":23,"y":10,"w":157,"h":172},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00006.png":
    {
        "frame": {"x":1,"y":344,"w":152,"h":170},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":25,"y":12,"w":152,"h":170},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00007.png":
    {
        "frame": {"x":173,"y":353,"w":144,"h":158},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":27,"y":21,"w":144,"h":158},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00008.png":
    {
        "frame": {"x":366,"y":1,"w":137,"h":151},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":29,"y":24,"w":137,"h":151},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00009.png":
    {
        "frame": {"x":365,"y":285,"w":132,"h":130},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":30,"y":31,"w":132,"h":130},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00010.png":
    {
        "frame": {"x":409,"y":882,"w":91,"h":95},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":52,"y":47,"w":91,"h":95},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00011.png":
    {
        "frame": {"x":409,"y":786,"w":94,"h":96},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":50,"y":46,"w":94,"h":96},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00012.png":
    {
        "frame": {"x":313,"y":888,"w":94,"h":99},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":49,"y":44,"w":94,"h":99},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00013.png":
    {
        "frame": {"x":1,"y":931,"w":96,"h":89},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":48,"y":54,"w":96,"h":89},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00014.png":
    {
        "frame": {"x":212,"y":888,"w":99,"h":101},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":46,"y":44,"w":99,"h":101},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00015.png":
    {
        "frame": {"x":108,"y":860,"w":101,"h":102},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":45,"y":44,"w":101,"h":102},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00016.png":
    {
        "frame": {"x":232,"y":784,"w":103,"h":102},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":44,"y":46,"w":103,"h":102},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00017.png":
    {
        "frame": {"x":1,"y":824,"w":105,"h":105},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":43,"y":45,"w":105,"h":105},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00018.png":
    {
        "frame": {"x":121,"y":750,"w":108,"h":109},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":42,"y":43,"w":108,"h":109},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00019.png":
    {
        "frame": {"x":253,"y":672,"w":110,"h":113},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":41,"y":41,"w":110,"h":113},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00020.png":
    {
        "frame": {"x":1,"y":709,"w":113,"h":118},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":40,"y":38,"w":113,"h":118},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00021.png":
    {
        "frame": {"x":387,"y":668,"w":116,"h":118},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":39,"y":36,"w":116,"h":118},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00022.png":
    {
        "frame": {"x":387,"y":547,"w":119,"h":119},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":37,"y":37,"w":119,"h":119},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00023.png":
    {
        "frame": {"x":129,"y":626,"w":122,"h":122},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":36,"y":36,"w":122,"h":122},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00024.png":
    {
        "frame": {"x":133,"y":499,"w":125,"h":127},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":35,"y":34,"w":125,"h":127},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00025.png":
    {
        "frame": {"x":262,"y":547,"w":123,"h":123},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":34,"y":32,"w":123,"h":123},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00026.png":
    {
        "frame": {"x":1,"y":581,"w":126,"h":126},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":33,"y":30,"w":126,"h":126},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00027.png":
    {
        "frame": {"x":333,"y":417,"w":128,"h":129},
        "rotated": true,
        "trimmed": true,
        "spriteSourceSize": {"x":32,"y":28,"w":128,"h":129},
        "sourceSize": {"w":198,"h":198}
    },
    "fx_hype_00028.png":
    {
        "frame": {"x":1,"y":498,"w":130,"h":81},
        "rotated": false,
        "trimmed": true,
        "spriteSourceSize": {"x":31,"y":78,"w":130,"h":81},
        "sourceSize": {"w":198,"h":198}
    }},
    "animations": {
        "fx_hype": ["fx_hype_00001.png","fx_hype_00002.png","fx_hype_00003.png","fx_hype_00004.png","fx_hype_00005.png","fx_hype_00006.png","fx_hype_00007.png","fx_hype_00008.png","fx_hype_00009.png","fx_hype_00010.png","fx_hype_00011.png","fx_hype_00012.png","fx_hype_00013.png","fx_hype_00014.png","fx_hype_00015.png","fx_hype_00016.png","fx_hype_00017.png","fx_hype_00018.png","fx_hype_00019.png","fx_hype_00020.png","fx_hype_00021.png","fx_hype_00022.png","fx_hype_00023.png","fx_hype_00024.png","fx_hype_00025.png","fx_hype_00026.png","fx_hype_00027.png","fx_hype_00028.png"]
    },
    "meta": {
        "app": "https://www.codeandweb.com/texturepacker",
        "version": "1.0",
        "image": "explosion6.png",
        "format": "RGBA8888",
        "size": {"w":507,"h":1021},
        "scale": "0.33",
        "smartupdate": "$TexturePacker:SmartUpdate:9905db541b0af660abd8a4ff3db255cb:fa8408fd574c0f6fac850cd3353899bc:c72fca3ba20c40bec4942df53d447db3$"
    }
    }    
    
`;
const explosionJson = `
    {"frames": [

    {
        "filename": "fx_hype_00001.png",
        "frame": {"x":0,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00002.png",
        "frame": {"x":150,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00003.png",
        "frame": {"x":300,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00004.png",
        "frame": {"x":450,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00005.png",
        "frame": {"x":600,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00006.png",
        "frame": {"x":750,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00007.png",
        "frame": {"x":900,"y":0,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00008.png",
        "frame": {"x":0,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00009.png",
        "frame": {"x":150,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00010.png",
        "frame": {"x":300,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00011.png",
        "frame": {"x":450,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00012.png",
        "frame": {"x":600,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00013.png",
        "frame": {"x":750,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00014.png",
        "frame": {"x":900,"y":150,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00015.png",
        "frame": {"x":0,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00016.png",
        "frame": {"x":150,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00017.png",
        "frame": {"x":300,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00018.png",
        "frame": {"x":450,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00019.png",
        "frame": {"x":600,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00020.png",
        "frame": {"x":750,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00021.png",
        "frame": {"x":900,"y":300,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00022.png",
        "frame": {"x":0,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00023.png",
        "frame": {"x":150,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00024.png",
        "frame": {"x":300,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00025.png",
        "frame": {"x":450,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00026.png",
        "frame": {"x":600,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00027.png",
        "frame": {"x":750,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    },
    {
        "filename": "fx_hype_00028.png",
        "frame": {"x":900,"y":450,"w":150,"h":150},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":150,"h":150},
        "sourceSize": {"w":150,"h":150}
    }],
    "meta": {
        "app": "https://www.codeandweb.com/texturepacker",
        "version": "1.0",
        "image": "explosion4.png",
        "format": "RGBA8888",
        "size": {"w":1050,"h":600},
        "scale": "0.25",
        "smartupdate": "$TexturePacker:SmartUpdate:7180ba6677e727b7256e5792bd30ab7c:fa8408fd574c0f6fac850cd3353899bc:058c6a5b66b837809fe8ee384b183ea5$"
    }
    }
    
`;

const explosion = JSON.parse(explosionJson);

const explosionPIXI = JSON.parse(explosionJsonPIXI);

export { explosion, explosionPIXI };
