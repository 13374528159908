const lockupString = `
    {"frames": [

    {
        "filename": "logo_animation_00049.png",
        "frame": {"x":0,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00050.png",
        "frame": {"x":422,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00051.png",
        "frame": {"x":844,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00052.png",
        "frame": {"x":1266,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00053.png",
        "frame": {"x":1688,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00054.png",
        "frame": {"x":2110,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00055.png",
        "frame": {"x":2532,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00056.png",
        "frame": {"x":2954,"y":0,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00057.png",
        "frame": {"x":0,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00058.png",
        "frame": {"x":422,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00059.png",
        "frame": {"x":844,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00060.png",
        "frame": {"x":1266,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00061.png",
        "frame": {"x":1688,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00062.png",
        "frame": {"x":2110,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00063.png",
        "frame": {"x":2532,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00064.png",
        "frame": {"x":2954,"y":399,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00065.png",
        "frame": {"x":0,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00066.png",
        "frame": {"x":422,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00067.png",
        "frame": {"x":844,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00068.png",
        "frame": {"x":1266,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00069.png",
        "frame": {"x":1688,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00070.png",
        "frame": {"x":2110,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00071.png",
        "frame": {"x":2532,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00072.png",
        "frame": {"x":2954,"y":798,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00073.png",
        "frame": {"x":0,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00074.png",
        "frame": {"x":422,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00075.png",
        "frame": {"x":844,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00076.png",
        "frame": {"x":1266,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00077.png",
        "frame": {"x":1688,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00078.png",
        "frame": {"x":2110,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00079.png",
        "frame": {"x":2532,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00080.png",
        "frame": {"x":2954,"y":1197,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00081.png",
        "frame": {"x":0,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00082.png",
        "frame": {"x":422,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00083.png",
        "frame": {"x":844,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00084.png",
        "frame": {"x":1266,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00085.png",
        "frame": {"x":1688,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00086.png",
        "frame": {"x":2110,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00087.png",
        "frame": {"x":2532,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00088.png",
        "frame": {"x":2954,"y":1596,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00089.png",
        "frame": {"x":0,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00090.png",
        "frame": {"x":422,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00091.png",
        "frame": {"x":844,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00092.png",
        "frame": {"x":1266,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00093.png",
        "frame": {"x":1688,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00094.png",
        "frame": {"x":2110,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00095.png",
        "frame": {"x":2532,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00096.png",
        "frame": {"x":2954,"y":1995,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00097.png",
        "frame": {"x":0,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00098.png",
        "frame": {"x":422,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00099.png",
        "frame": {"x":844,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00100.png",
        "frame": {"x":1266,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00101.png",
        "frame": {"x":1688,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00102.png",
        "frame": {"x":2110,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00103.png",
        "frame": {"x":2532,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00104.png",
        "frame": {"x":2954,"y":2394,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00105.png",
        "frame": {"x":0,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00106.png",
        "frame": {"x":422,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00107.png",
        "frame": {"x":844,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00108.png",
        "frame": {"x":1266,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00109.png",
        "frame": {"x":1688,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00110.png",
        "frame": {"x":2110,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00111.png",
        "frame": {"x":2532,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00112.png",
        "frame": {"x":2954,"y":2793,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00113.png",
        "frame": {"x":0,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00114.png",
        "frame": {"x":422,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00115.png",
        "frame": {"x":844,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00116.png",
        "frame": {"x":1266,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00117.png",
        "frame": {"x":1688,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00118.png",
        "frame": {"x":2110,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00119.png",
        "frame": {"x":2532,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00120.png",
        "frame": {"x":2954,"y":3192,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00121.png",
        "frame": {"x":0,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00122.png",
        "frame": {"x":422,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00123.png",
        "frame": {"x":844,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00124.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00125.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00126.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00127.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00128.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00129.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00130.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    },
    {
        "filename": "logo_animation_00131.png",
        "frame": {"x":1266,"y":3591,"w":422,"h":399},
        "rotated": false,
        "trimmed": false,
        "spriteSourceSize": {"x":0,"y":0,"w":422,"h":399},
        "sourceSize": {"w":422,"h":399}
    }],
    "meta": {
        "app": "https://www.codeandweb.com/texturepacker",
        "version": "1.0",
        "image": "lockup2.png",
        "format": "RGBA8888",
        "size": {"w":3376,"h":3990},
        "scale": "0.66",
        "smartupdate": "$TexturePacker:SmartUpdate:7220581ae9967e56d5dbf25902c1dd12:022703a9c51433264ab3094ae8a2ed89:65f75488f4c7085a30b05fde7e8c4141$"
    }
    }
    
    
`;

const lockup = JSON.parse(lockupString);

export { lockup };
