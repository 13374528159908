
    import GemIcon from './images/GemIcon.vue';
    import ClockIcon from './images/ClockIcon.vue';
    export default {
        components: {
            GemIcon,
            ClockIcon,
        },
        props: {
            time: Number,
            score: Number,
        },
        computed: {
            formattedTime () {
                return this.time !== undefined && this.time !== null ? (this.time.toString().length < 2 ? `0${this.time.toString()}` : this.time.toString()) : '00';
            },
        },
    };
