
    export default {
        props: {
            dismissable: Boolean,
        },
        methods: {
            dismiss () {
                this.$emit('dismiss');
            },
        },
    };
