import * as THREE from 'three';
import jstrig from 'jstrig';
import gsap, { Linear } from 'gsap';
import BaseController from './engine/controllers/BaseController';
// import LightController, {LightTypes} from './engine/controllers/LightController';
// import ShadowController from './engine/controllers/ShadowController';
import { generateID, degreesToRadians, cubicBezier, radiansToDegrees } from './utils/Utilities';
import { basicImageMaterial } from './utils/THREEHelpers';
import { createMesh } from './utils/GameUtils';

export default class ThrowableController extends BaseController {
    constructor (data, config) {
        super(data);
        this.config = config;
        this.throwable = null;
        this.throwableChild = null;
        if (this.config.thrower.start_position) {
            this.startPosition = this.config.thrower.start_position.join ? this.config.thrower.start_position[0] : this.config.thrower.start_position;
        }
        this.canThrow = false;
        this.canScore = true;
        this.paused = false;
        this.throwProperties = {
            start: {},
            end: {},
            current: {},
        };
        this.throwDuration = this.config.throw_duration;
        this.currentTweenKey = '';
        this.hitDistance = this.config.score_proximity;
        this.onHit = null;
        this.onThrowStarted = null;
        this.onThrowCompleted = null;
        this.onThrowableMoved = null;
        this.shadowSprite = null;
        this.throwIndex = 0;
        this.throwMax = this.config.camera_position.join ? this.config.camera_position.length : 0;
        if (this.config.camera_rotation && this.config.camera_rotation.join) {
            this.throwMax = this.config.camera_rotation.length > this.throwMax ? this.config.camera_rotation.length : this.throwMax;
        }
        createMesh(this.environment, this.config.thrower, (mesh) => this.createThrowable(mesh));

    }
    createThrowable (customMesh) {
        this.throwableChild = customMesh;
        this.throwable = new THREE.Object3D();
        this.environment.scene.add(this.throwable);
        this.throwable.add(this.throwableChild);
        if (this.config.thrower.shadow) {
            const tex = this.config.thrower.shadow.image;
            const material = basicImageMaterial(tex);
            const shadow = this.environment.createPlane({ size: { ...this.config.thrower.shadow.size, z: 0 }, position: { x: this.throwable.position.x, y: .01, z: this.throwable.position.z }, material });
            this.shadowSprite = shadow.mesh;
            this.shadowSprite.rotation.x = degreesToRadians(-90);
        }
        this.reset();
        this.canThrow = true;
    }
    reset () {
        this.throwable.position.x = this.getStartPosition().x;
        this.throwable.position.y = this.getStartPosition().y;
        this.throwable.position.z = this.getStartPosition().z;
        this.throwableChild.rotation.x = 0;
        this.throwableChild.rotation.y = 0;
        this.throwableChild.rotation.z = 0;
        this.throwable.rotation.y = 0;
        this.canScore = true;
        if (this.shadowSprite) {
            this.shadowSprite.position.x = this.throwable.position.x;
            this.shadowSprite.position.z = this.throwable.position.z;
            this.shadowSprite.rotation.z = 0;
        }
        if (this.onThrowCompleted) {
            this.onThrowCompleted();
        }
    }
    getStartPosition (indexOverride) {
        const index = indexOverride !== undefined ? indexOverride : this.throwIndex;
        return this.config.thrower.start_position.join ? this.config.thrower.start_position[index] : this.config.thrower.start_position;
    }
    pause () {
        this.paused = true;
    }
    unPause () {
        this.paused = false;
        if (this.throwProperties.current.progress > 0 && this.throwProperties.current.progress < 1) {
            this.throwProperties.start = {
                ...this.throwProperties.current,
            };
            this.animateThrow(this.throwProperties.start, this.throwProperties.end, this.throwDuration * this.throwProperties.current.progress);
        }
    }
    animateThrow (throwStats, throwEnd, duration) {
        if (this.onThrowStarted) {
            this.onThrowStarted();
        }
        this.currentTweenKey = `tween-${generateID()}`;
        const tweenKey = this.currentTweenKey;
        let pointMatrix = [];
        let wieghtSegments = [];
        if (this.config.thrower.throw_path) {
            let cumulation = 0;
            const weights = [];
            // console.log(pointMatrix);

            pointMatrix = [...JSON.parse(JSON.stringify(this.config.thrower.throw_path))];

            pointMatrix.forEach(item => {
                item.curve.forEach((_item, _index) => {
                    item.curve[_index][0] *= (throwStats.force / 100) * -1;
                    // item.curve[_index][0] += startinPoing;
                    item.curve[_index][1] *= (throwStats.force / 100);
                });
                const currentValue = item.weight + cumulation;
                // item.length >= 5 ? currentValue : index;
                weights.push(currentValue);
                cumulation += item.weight;
            });
            const weightFactor = 1 / weights[weights.length - 1];
            // const progress = { n: 0 };
            wieghtSegments = weights.map((item, index) => ({ index, in: index >= 1 ? weights[index - 1] * weightFactor  : 0, out: item * weightFactor }));

            // console.log(pointMatrix);
        }

        gsap.to(throwStats, {
            duration,
            ...throwEnd,
            ease: Linear.easeNone,
            onUpdate: () => {
                if (!this.paused && tweenKey === this.currentTweenKey) {
                    let baseArc;
                    let refinedArc;
                    if (pointMatrix.length) {
                        const reverseProgress = 1 - throwStats.progress;
                        const currentSection = wieghtSegments.find(item => reverseProgress >= item.in && reverseProgress <= item.out);
                        const ratio = (reverseProgress - currentSection.in) * (1 / (currentSection.out - currentSection.in));
                        const matrixEntry = pointMatrix[currentSection.index].curve;
                        const pos = cubicBezier(
                            ratio,
                            { x: matrixEntry[0][0], y: matrixEntry[0][1] },
                            { x: matrixEntry[1][0], y: matrixEntry[1][1] },
                            { x: matrixEntry[2][0], y: matrixEntry[2][1] },
                            { x: matrixEntry[3][0], y: matrixEntry[3][1] },
                        );
                        baseArc = { z: pos.x, y: pos.y + this.getStartPosition(throwStats.tempThrowIndex).y };
                        refinedArc = {
                            x: jstrig.orbit(this.getStartPosition(throwStats.tempThrowIndex).x, baseArc.z, throwStats.lateral, 'cos'),
                            z: jstrig.orbit(this.getStartPosition(throwStats.tempThrowIndex).z, baseArc.z, throwStats.lateral, 'sin') * -1,
                        };

                    }
                    else {
                        baseArc = this.config.elevation === 0 ?
                            {
                                z: throwStats.baseDistance * (1 - throwStats.progress) * -2,
                                y: this.throwable.position.y,
                            } :
                            {
                                z: jstrig.orbit(throwStats.center.z, throwStats.distance, throwStats.angle, 'cos'),
                                y: jstrig.orbit(throwStats.center.y, throwStats.distance * (this.config.elevation ? this.config.elevation : 1), throwStats.angle, 'sin'),
                            };
                        refinedArc = {
                            x: jstrig.orbit(this.getStartPosition(throwStats.tempThrowIndex).x, baseArc.z, throwStats.lateral + throwStats.crStart.y, 'cos'),
                            z: jstrig.orbit(this.getStartPosition(throwStats.tempThrowIndex).z, baseArc.z, throwStats.lateral + throwStats.crStart.y, 'sin') * -1,
                        };

                    }
                    this.throwable.position.y = baseArc.y;
                    this.throwable.position.z = refinedArc.z + (this.getStartPosition(throwStats.tempThrowIndex).z * 2);
                    this.throwable.position.x = refinedArc.x;

                    this.throwableChild.rotation.x = throwStats.rotationX;
                    this.throwableChild.rotation.y = throwStats.rotationY;
                    this.throwableChild.rotation.z = throwStats.rotationZ;
                    this.throwable.rotation.y = degreesToRadians(throwStats.lateral);


                    // console.log(throwStats.lateral);

                    this.throwProperties.current = { ...throwStats };
                    if (this.shadowSprite) {
                        this.shadowSprite.position.x = this.throwable.position.x;
                        this.shadowSprite.position.z = this.throwable.position.z;
                        this.shadowSprite.rotation.z = this.throwableChild.rotation.y + this.throwable.rotation.y;
                    }
                    if (this.onThrowableMoved) {
                        this.onThrowableMoved(this.throwable);
                    }
                }
            },
            onComplete: () => {
                if (!this.paused && tweenKey === this.currentTweenKey) {
                    this.canThrow = true;
                    this.shiftCamera(duration);
                    this.reset();
                }
            },
        });

    }
    shiftCamera (duration) {
        if (this.throwMax) {
            this.throwIndex++;
            if (this.throwIndex >= this.throwMax) {
                this.throwIndex = 0;
            }
            const camStats = {};
            const currentCamStats = {};
            const hasPosition = this.config.camera_position.join;
            const hasRotation = this.config.camera_rotation.join;
            if (hasPosition) {
                const camPos = JSON.parse(JSON.stringify(this.config.camera_position[this.throwIndex]));
                camStats.px = camPos.x;
                camStats.py = camPos.y;
                camStats.pz = camPos.z;
                currentCamStats.px = this.environment.cameraContainer.position.x;
                currentCamStats.py = this.environment.cameraContainer.position.y;
                currentCamStats.pz = this.environment.cameraContainer.position.z;
            }
            if (hasRotation) {
                const camRot = JSON.parse(JSON.stringify(this.config.camera_rotation[this.throwIndex]));
                camStats.rx = degreesToRadians(camRot.x);
                camStats.ry = degreesToRadians(camRot.y);
                camStats.rz = degreesToRadians(camRot.z);
                currentCamStats.rx = this.environment.cameraContainer.rotation.x;
                currentCamStats.ry = this.environment.cameraContainer.rotation.y;
                currentCamStats.rz = this.environment.cameraContainer.rotation.z;
            }

            gsap.to(currentCamStats, {
                duration: duration * .25,
                ...camStats,
                onUpdate: () => {
                    if (hasPosition) {
                        this.environment.cameraContainer.position.x = currentCamStats.px;
                        this.environment.cameraContainer.position.y = currentCamStats.py;
                        this.environment.cameraContainer.position.z = currentCamStats.pz;
                    }
                    if (hasRotation) {
                        this.environment.cameraContainer.rotation.x = currentCamStats.rx;
                        this.environment.cameraContainer.rotation.y = currentCamStats.ry;
                        this.environment.cameraContainer.rotation.z = currentCamStats.rz;
                    }
                },
            });

        }
    }
    async launchThrowable ({ x, z }) {
        if (this.canThrow) {
            this.canThrow = false;
            const force = Math.abs(z);
            const center = {
                z: jstrig.orbit(0, force, -90, 'cos'),
                y: jstrig.orbit(0, force, -90, 'sin'),
            };
            const startAngle = jstrig.angle({ x: center.z, y: center.y }, { x: 0, y: this.getStartPosition().y });
            const startDistance = jstrig.distance({ x: center.z, y: center.y }, { x: 0, y: this.getStartPosition().y });
            const throwStats = {
                angle: startAngle,
                distance: startDistance,
                baseDistance: startDistance,
                lateral: x,
                center,
                force,
                progress: 1,
                rotationX: 0,
                rotationY: 0,
                rotationZ: 0,
                // cpStart: {x: this.environment.cameraContainer.position.x, y: this.environment.cameraContainer.position.y, z: this.environment.cameraContainer.position.x},
                crStart: { x: radiansToDegrees(this.environment.cameraContainer.rotation.x), y: radiansToDegrees(this.environment.cameraContainer.rotation.y), z: radiansToDegrees(this.environment.cameraContainer.rotation.x) },
                tempThrowIndex: this.throwIndex,
            };
            const throwEnd = {
                angle: 270,
                distance: startDistance * .5,
                progress: 0,
                rotationX: this.config.thrower.throw_rotation && this.config.thrower.throw_rotation.x !== undefined ? degreesToRadians(this.config.thrower.throw_rotation.x) : (Math.random() * 10) - 5,
                rotationY: this.config.thrower.throw_rotation && this.config.thrower.throw_rotation.y !== undefined ? degreesToRadians(this.config.thrower.throw_rotation.y) : (Math.random() * 10) - 5,
                rotationZ: this.config.thrower.throw_rotation && this.config.thrower.throw_rotation.z !== undefined ? degreesToRadians(this.config.thrower.throw_rotation.z) : (Math.random() * 10) - 5,
            };
            this.throwProperties.start = { ...throwStats };
            this.throwProperties.end = { ...throwEnd };
            this.throwProperties.current = { ...throwStats };
            this.animateThrow(throwStats, throwEnd, this.throwDuration);
            while (!this.canThrow) {
                await new Promise(resolve => setTimeout(resolve, 10));
            }
        }
    }
}
