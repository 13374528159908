
    import FacebookIcon from './images/FacebookIcon.vue';
    import TwitterIcon from './images/TwitterIcon.vue';
    // import EmailIcon from './images/EmailIcon.vue';
    import LinkedInIcon from './images/LinkedInIcon.vue';
    const gameURL = 'https://www.dentsugemquest.com';
    export default {
        components: {
            FacebookIcon,
            TwitterIcon,
            // EmailIcon,
            LinkedInIcon,
        },
        data () {
            return {
                fb: {
                    title: 'HIT YOUR TARGET GAMER AUDIENCE | DENTSU GAMING',
                    url: gameURL,
                    image: 'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/gemquestshareimage.jpg',
                    description: 'Download the report For The Game to learn how to connect more authentically with gamers.',
                },
                twitter: {
                    title: 'HIT YOUR TARGET GAMER AUDIENCE by DENTSU GAMING',
                    url: gameURL,
                    image: 'https://eprize-content.s3.amazonaws.com/three_js_demo/assets/gemquestshareimage.jpg',
                    description: 'Download the report For The Game to learn how to connect more authentically with gamers.',
                    hashtags: '',
                },
                email: {
                    subject: 'HIT YOUR TARGET GAMER AUDIENCE | DENTSU GAMING',
                    body: `Download the report For The Game to learn how to connect more authentically with gamers. ${gameURL}`,
                },
                linkedIn: {
                    title: 'HIT YOUR TARGET GAMER AUDIENCE | DENTSU GAMING',
                    url: gameURL,
                },
            };
        },
        methods: {
            fbShare () {
                window.open(
                    `http://www.facebook.com/sharer.php?s=100&p[title]=${this.fb.title}&p[summary]=${this.fb.description}&p[url]=${this.fb.url}&p[images][0]=${this.fb.image}`,
                    'facebook-share-dialog',
                    'width=626,height=436',
                );
            },
            twitterShare () {
                window.open(
                    `http://twitter.com/share?text=${this.twitter.title}&url=${this.twitter.url}&hashtags=${this.twitter.hashtags}`,
                    'twitter-share-dialog',
                    'width=626,height=436',
                );

            },
            linkedInShare () {
                window.open(
                    `http://www.linkedin.com/shareArticle?url=${this.linkedIn.url}`,
                    'linkedin-share-dialog',
                    'width=626,height=436',
                );

            },
        },
    };
